import { sleep } from '@smu-chile/pkg-unimarc-hooks'
import { useEffect } from 'react'

/**
 * Custom hook that handles URL parameters by removing unnecessary session-related parameters and controlling a modal display.
 *
 * @param {object} router - The Next.js router object to access and manipulate the current route and query parameters.
 * @param {Function} setShowModalMembershipLevelQuery - A state setter function to toggle the visibility of the membership modal.
 *
 * @example
 * import { useRouter } from 'next/router';
 * import { useState } from 'react';
 * import useHandleUrlParams from './useHandleUrlParams';
 *
 * const MyComponent = () => {
 *   const router = useRouter();
 *   const [showModal, setShowModal] = useState(false);
 *
 *   useHandleUrlParams(router, setShowModal);
 *
 *   return <div>{showModal && <MembershipModal />}</div>;
 * };
 */
export const useHandleUrlParams = (
  router,
  setShowModalMembershipLevelQuery
) => {
  useEffect(() => {
    const removeSessionAndUnusedParams = () => {
      const { query, pathname } = router
      const ignoreItems = ['q', 'page', 'source', 'GoSSO', 'GotoMembership']
      const saveSearchQuery = {}

      Object.keys(query).forEach((key) => {
        if (ignoreItems.includes(key)) {
          saveSearchQuery[key] = query[key]
        }
      })

      const paramsUrl = new URLSearchParams(saveSearchQuery).toString()
      const validateParams = paramsUrl ? `?${paramsUrl}` : ''

      // Verifica si hay parámetros relacionados con sesión u otros no necesarios
      if (query.orderform_id || query.recatoken || query.sc) {
        const newUrl = `${pathname}${validateParams}`
        if (newUrl !== window.location.href) {
          router.replace(newUrl) // Solo reemplaza si es necesario
        }
      }
    }

    const showModalMembershipLevelQueryByTime = async () => {
      await sleep(1500)

      // Muestra el modal si el parámetro `membresia` está presente
      if (router.query.membresia) {
        setShowModalMembershipLevelQuery(true)
      }
    }

    removeSessionAndUnusedParams()
    showModalMembershipLevelQueryByTime()
  }, [router, setShowModalMembershipLevelQuery])
}
