import { FeaturedButtons } from 'components/ClubAhorro/ClubAhorroContent/FeaturedButtons'
import StaticBanner from 'components/ClubAhorro/ClubAhorroStaticBanner/StaticBanner'
import { ClubFeatureCarousel } from './ClubFeatureCarousel'
import { ImageBanner } from 'shared/interfaces/ClubUnimarc'
import { ClubFeatureBanners } from './ClubFeatureBanners/ClubFeatureBanners'

export enum ClubFeatureLayout {
  Banner = 'banner',
  Carousel = 'carousel'
}

export type ClubFeatureProps = {
  bottomBanner?: boolean
  height?: number
  idFormato?: number
  imageBanner?: unknown[]
  isBanner?: boolean
  label?: string
  layout?: string
  subtitle?: string
  title?: string
  topBanner?: boolean
  isMobile?: boolean
  sectionIcon?: string
}

export const ClubFeature = ({
  bottomBanner,
  height,
  idFormato,
  imageBanner,
  isBanner,
  label,
  layout,
  subtitle,
  title,
  topBanner
}: ClubFeatureProps) => {
  const isBottomBanner = bottomBanner === true
  const isTopBanner = topBanner === true
  const isContentBanner = !isBottomBanner && !isTopBanner

  const bannersProps = () => {
    return {
      imageBanner,
      layout,
      showArrows: isTopBanner
    }
  }

  const carouselProps = () => {
    return {
      imageBanner,
      isInfinite: isTopBanner,
      showArrows: isTopBanner
    }
  }

  const featureButtonsProps = () => {
    return {
      content: {
        height,
        idFormato,
        imageBanner: imageBanner as ImageBanner[],
        label,
        layout,
        subtitle,
        title
      }
    }
  }

  const staticBannerProps = () => {
    const [first] = imageBanner
    return {
      imgUrl: first?.['image']?.['fields']?.['file']?.['url']
    }
  }

  return (
    <>
      {layout === ClubFeatureLayout.Banner && isContentBanner && (
        <FeaturedButtons {...featureButtonsProps()} />
      )}

      {layout === ClubFeatureLayout.Banner && isBottomBanner && (
        <StaticBanner {...staticBannerProps()} />
      )}

      {layout === ClubFeatureLayout.Banner && isTopBanner && (
        <StaticBanner {...staticBannerProps()} />
      )}

      {layout === ClubFeatureLayout.Carousel && !isBanner && (
        <ClubFeatureCarousel {...carouselProps()} />
      )}

      {isBanner && <ClubFeatureBanners {...bannersProps()} />}

      {/** add here new features by content-type */}
    </>
  )
}
