/**
 * Validates a string to determine whether to replace hyphens with spaces
 * based on the presence of numbers, letters, and more than four consecutive zeros.
 *
 * @param {string} str - The string to be validated and modified.
 * @returns {string} - The modified string with hyphens replaced by spaces or the original string if conditions are met.
 */

export const cleanSearching = (str) => {
  // Check if the string contains a hyphen
  if (!str.includes('-')) return str

  // Check if the string contains numbers and letters
  const hasNumbers = /\d/.test(str)
  const hasLetters = /[a-zA-Z]/.test(str)

  // Check if the string has more than four consecutive zeros
  const moreThanFourZeros = /(0{4,})/.test(str)

  // If the string has numbers, letters, and more than four zeros, don't replace the hyphen
  if (hasNumbers && hasLetters && moreThanFourZeros) {
    return str
  }

  // If it doesn't meet the above condition, replace the hyphen with a space
  return str.replace(/(?<!\d{4})-/g, ' ')
}
