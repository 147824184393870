import { BannersClubAhorro } from 'shared/interfaces/ClubUnimarc'
import { FeaturedButtons } from '../FeaturedButtons'
import { NextRouter } from 'next/router'

export interface ClubBannerProps {
  content: BannersClubAhorro
  index?: string
  isLoggedIn?: boolean
  isMobile?: boolean
  router?: NextRouter
  unipayStatus?: 'preapproved' | 'holder' | null
  onClickAllianceBanner?: (allianceId?) => void
  onClickBanner?: () => void
  sectionIcon?: string
}
export const ClubBanner = ({
  content,
  index,
  isLoggedIn,
  isMobile,
  router,
  unipayStatus,
  onClickAllianceBanner,
  onClickBanner,
  sectionIcon
}: ClubBannerProps) => {
  if (
    content.label.includes('preapprovedUnipayCard') ||
    content.label.includes('isUnipayCardHolder')
  ) {
    const handleClick = () => {
      router.push(content?.imageBanner?.[0]?.referenceWeb)
    }
    if (content.label.toLocaleLowerCase().includes(unipayStatus)) {
      return (
        <FeaturedButtons
          content={content}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile}
          key={index}
          onClickAllianceBanner={onClickAllianceBanner}
          onClickBanner={handleClick}
          sectionIcon={sectionIcon}
        />
      )
    }

    return null
  }

  return (
    <FeaturedButtons
      content={content}
      isLoggedIn={isLoggedIn}
      isMobile={isMobile}
      key={index}
      onClickAllianceBanner={onClickAllianceBanner}
      onClickBanner={onClickBanner}
      sectionIcon={sectionIcon}
    />
  )
}
