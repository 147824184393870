import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import {
  Container,
  HeaderPrototype,
  IconProps,
  Overlay
} from '@smu-chile/pkg-unimarc-components'
import { Context as ResponsiveContext } from '@smu-chile/pkg-unimarc-components/reactResponsive'
import { validateSelectedAddressExist } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/validateSelectedAddressExist'
import { callToPostSlaSimulation } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/callToPostSlaSimulation/index'
import { validateSlaValueExist } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/validateSlaValueExist'
import { BigScreen } from '@smu-chile/pkg-unimarc-components/helpers/mediaQueries'
import { StoreLocatorHeaderProps } from '@smu-chile/pkg-unimarc-components/stories/organisms/Header'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  checkUserLoginFlow,
  Cookies,
  currencyFormat,
  datalayerSimpleEvent,
  eventClick,
  getContentfulDesktopImage,
  getContentfulHeaderLinks,
  getContentfulImageCategories,
  getContentfulOffersLinks,
  goToHome,
  tagClickCouponIconInHeader,
  TagLoginRef,
  tagLoginView,
  useCache,
  useCategories,
  useContentful,
  useEvents,
  useOrderForm,
  useSearchV2,
  useSession,
  useSessionState,
  useTrigerEvent,
  useUpdateFromShelf,
  logoutV2,
  useMobile,
  useUserWithMembership,
  useQueryClient,
  sleep,
  updateCurierName,
  UseQueryResult,
  productClickTag,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { LoginModalBrowse } from 'components/LoginModalBrowse'
import { formatDate } from 'shared/utils/formatDate'
import {
  aislesProps,
  searchProps,
  handleUserName,
  shouldOpenModal,
  handleSelectAddress,
  useBorderBottomByPage
} from './helpers'
import { variableBreakdownCF } from './helpers/variableBreakdownCF'
import { MODAL_LOGOUT_STRAWBERRY, OFFERS_URL } from 'shared/utils/constanst'
import { SSOWelcomeModal } from 'components/SSOLayout/WelcomeModal'
import { HeaderMembership } from './components/HeaderMembership'
import { HeaderClubSavingModal } from './components/HeaderClubSavingModal'
import { HeaderClubBenefitsModal } from './components/HeaderClubBenefitsModal'
import { cleanSearching } from 'shared/helpers'
import { ProductDetailModalWrapper } from 'components/ProductDetailModalWrapper'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'
import {
  LocalOrderFormQuery,
  useLocalAddress
} from 'shared/utils/hooks/useLocalAddress'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import { ILastSeenProducts } from 'shared/interfaces/ILastSeenProducts'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import {
  tagMarksCategories,
  tagMarksClickedCategories
} from './helpers/tagMarksCategories'
import styles from './HeaderBrowse.module.css'
import { dropDownSearchOpacityOn } from 'shared/helpers/dropDownSearchOpacityOn'

export interface HeaderBrowseProps {
  cartData?: ICart
  children?: React.ReactNode
  isDownloadToastOpen?: boolean
  isMobile?: boolean
}

const processedElements = new Set()
const USER_NAME_CACHE_KEY = 'username'

export const HeaderBrowse = ({
  cartData,
  children,
  isDownloadToastOpen,
  isMobile
}: HeaderBrowseProps): React.ReactElement => {
  // use
  const router = useRouter()
  const queryClient = useQueryClient()
  const { trigger } = useTrigerEvent()
  const { innerWidth } = useMobile()
  const { isLoggedIn, isLoading } = useSession()
  const { isMobile: device } = useMobile()
  const { result, handleChangeSearch } = useSearchV2()
  const headerContainer = useRef(null)
  const columnSearchRef = useRef(null)
  const [showPromoHeader, setShowPromoHeader] = useSessionState(
    'showPromoHeader',
    true
  )
  const withProvider: number = useMemo(() => {
    const hasInnerWith: number = isMobile && !innerWidth ? 500 : 1400
    return innerWidth ? innerWidth : hasInnerWith
  }, [innerWidth, isMobile])

  const { cfDataHeader } = variableBreakdownCF()

  const categoriesApp = useContentful({
    options: {
      content_type: process?.env?.NEXT_PUBLIC_CATEGORIES_TYPE
    }
  })
  const imgOfOffers = useContentful({
    id_contentful: 'offers_img',
    options: {
      'fields.title[in]': 'Ofertas mobile'
    },
    reactQuery: {
      enabled: isMobile
    },
    type: 'assets'
  })
  const offersLink = useContentful({
    id_contentful: 'offersLink',
    options: {
      'sys.contentType.sys.id[in]': 'home',
      'sys.id[in]': OFFERS_URL
    }
  })
  const orderForm = useOrderForm()
  const headerData = useContentful({
    id_contentful: 'bannerWeb',
    options: {
      'sys.contentType.sys.id[in]': 'headerWeb'
    }
  })

  const imgModalLogout = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-logout',
    options: {
      'sys.id': MODAL_LOGOUT_STRAWBERRY[0]
    },
    type: 'assets'
  })
  const imageModalLogout =
    imgModalLogout?.data?.['items'][0]?.fields?.file?.url || ''

  const [showMembershipMenu, setShowMembershipMenu] = useState(true)
  const [hasLocalAddress, setHasLocalAddress] = useState(false)
  const [localQueryOrderForm, setLocalQueryOrderForm] =
    useState<LocalOrderFormQuery>({})
  const [isLoadingNextSearch, setIsLoadingNextSearch] = useState(false)
  const { data: dataMembership, isLoading: isLoadingMembership } =
    useUserWithMembership()
  const resultAisles = useCategories({ level: 2 })
  const inputAdornmentRef = useRef(null)
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [productSlug, setProductSlug] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isVisibleMyProfile, setIsVisibleMyProfile] = useState(false)
  const [isVisibleModalLogout, setIsVisibleModalLogout] = useState(false)
  const [isVisibleClearIcon, setIsVisibleClearIcon] = useState(false)
  const [value, setValue] = useState('')
  const [loginModalOpen, setLoginModalOpen] = useState(false)
  const [tagLoginRef, setTagLoginRef] = useState<TagLoginRef>(undefined)
  const [total, setTotal] = useState('')
  const [activeMenu, setActiveMenu] = useState('')
  const [sla, setSla] = useState('')
  const [isCheckingSla, setIsCheckingSla] = useState(false)
  const { editTempOrderForm, ...useUpdateProps } = useUpdateFromShelf({
    fromWhere: 'search',
    isOrderFormLoading,
    hasLocalAddress,
    products: result?.intelligenceSearch?.[
      'data'
    ]?.pages[0]?.availableProducts?.slice(0, 20)
  })

  // local address
  const { setLocalSla } = useLocalAddress()

  useEvents({
    eventType: 'localAddressStoreLocator',
    callBack: ({
      detail: {
        hasLocalAddress: hookHasLocalAddress,
        localQueryOrderForm: hookLocalQueryOrderFrom
      }
    }) => {
      if (!isLoggedIn) {
        setHasLocalAddress(hookHasLocalAddress)
        setLocalQueryOrderForm(hookLocalQueryOrderFrom)
      }
    }
  })

  // primitive
  const shouldLoginModalBeOpen = shouldOpenModal(
    router.isReady,
    router.query.login,
    isLoading,
    isLoggedIn
  )
  const userProfileId = orderForm?.data?.data?.userProfileId
  const { selectedAddresses, isPickup, addressSelected } = handleSelectAddress({
    orderForm: isLoggedIn
      ? orderForm
      : ({
          ...localQueryOrderForm,
          data: {
            ...localQueryOrderForm?.data,
            data: {
              ...localQueryOrderForm?.data?.data,
              selectedAddresses: {
                ...localQueryOrderForm?.data?.data?.selectedAddresses
              }
            }
          }
        } as UseQueryResult<ResponseOrderFormProps>)
  })
  const iconStoreLocatorLogin = isPickup ? 'Shop' : 'Motorcycle'
  const iconStoreLocator: StoreLocatorHeaderProps['iconStoreLocator'] =
    (isLoggedIn || hasLocalAddress) && selectedAddresses
      ? iconStoreLocatorLogin
      : 'Location'
  const linkOffers =
    offersLink.data?.['includes']?.['Entry']?.[0]?.fields?.text ||
    process.env.NEXT_PUBLIC_OFFERSURL
  const offersMobile = {
    url: linkOffers,
    img: imgOfOffers.data?.['items']?.[0]?.fields?.file?.url
  }
  const userName = handleUserName(
    isLoggedIn,
    orderForm?.data?.data?.clientProfileData?.firstName,
    orderForm?.data?.data?.clientProfileData?.lastName
  )
  const userFirstNameCached = useCache(USER_NAME_CACHE_KEY, userName)
  const headerContentfulData = { ...headerData?.data?.['items']?.[0]?.fields }
  const searchParams = router.query?.['q']
  // get
  const categoriesDataImage =
    categoriesApp?.data?.['items']?.length > 0
      ? getContentfulImageCategories(categoriesApp?.data)
      : {}
  const categoriesBannerImage =
    categoriesApp?.data?.['items']?.length > 0
      ? getContentfulDesktopImage(categoriesApp?.data)
      : {}
  const smallScreenSLVisible =
    typeof window !== 'undefined' &&
    !['/live-shopping-unimarc'].includes(window.location.pathname)
  const secondaryHeaderLinks =
    headerData?.data?.['items']?.length > 0
      ? getContentfulHeaderLinks(headerData?.data)
      : {}
  const offersLinks =
    headerData?.data?.['items']?.length > 0
      ? getContentfulOffersLinks(headerData?.data)
      : {}

  const hidePromoAndSecondaryHeader = router.pathname !== '/'

  const aislesPropsData = aislesProps({
    categoriesDataImage,
    categoriesBannerImage,
    isMobile,
    offersMobile,
    resultAisles
  })

  const {
    activeButtonColor,
    backgroundColorPrimary,
    backgroundColorPromo,
    backgroundColorSecondary,
    backgroundDropDownSearch,
    backgroundStoreLocatorMobile,
    colorBackgroundNumberCartIcon,
    colorBackgroundSearch,
    colorIconClosePromo,
    colorLogoHeader,
    colorNumberCartIcon,
    colorStoreLocator,
    colorTextHeaderPrincipal,
    colorTextLinkPromo,
    colorTextPromo,
    colorTextSearch,
    colorTextSecondaryHeader,
    hoversHeaderButtons,
    hoverTextSecondaryHeader,
    promoContent,
    promoLinkText,
    promoLinkUrl,
    showPromoBanner
  } = headerContentfulData

  const searchPropsData = searchProps({
    ...useUpdateProps,
    inputAdornmentRef,
    isLoadingNextSearch,
    isMobile,
    isVisible,
    isVisibleClearIcon,
    queryClient,
    result,
    router,
    value,
    handleChangeSearch,
    setActiveMenu,
    setIsFocused,
    setIsVisible,
    setIsVisibleClearIcon,
    setIsLoadingNextSearch,
    setValue
  })

  // add custom border bottom to header
  useBorderBottomByPage(router)

  // When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      editTempOrderForm(items, loading)
      setIsOrderFormLoading(loading)
    }
  })

  const handleClose = useCallback(() => {
    if (isVisible) setIsVisible(false)
    setActiveMenu('')
  }, [activeMenu, isVisible])

  const handleOpenLoginModal = (open = true, ref?: TagLoginRef) => {
    const url = new URL(window.location.href)
    if (open && ref) {
      tagLoginView(ref)
    }
    setTagLoginRef(ref)

    if (open) {
      url.searchParams.append('login', 'true')
      router.push(
        {
          pathname: url.pathname.toString(),
          query: url.searchParams.toString()
        },
        undefined,
        {
          shallow: true
        }
      )
    } else {
      url.searchParams.delete('login')
      router.replace(
        {
          pathname: url.pathname.toString(),
          query: url.searchParams.toString()
        },
        undefined,
        {
          shallow: true
        }
      )
    }
  }

  const handleCloseLoginModal = () => {
    handleOpenLoginModal(false)
  }

  const handleLogout = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'menu_cliente',
      eventLabel: 'cerrar_sesion'
    })
    Cookies.remove('addToCart')
    Cookies.remove('sessionNanoId')
    Cookies.remove('sessionAnonymousId')
    userFirstNameCached.clearCache()
    logoutV2()
  }

  const handleStoreLocator = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'donde_recibir_compra'
    })

    eventClick({
      eventCategory: 'interacciones en metodo de entrega',
      eventLabel: 'selector de direccion en header'
    })
    trigger({ eventType: 'deliverySelector', data: { show: true } })
  }

  const handleClickCart = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'carrito'
    })
    const validation = checkUserLoginFlow(
      isLoggedIn,
      orderForm?.data?.data?.selectedAddresses
    )

    if (validation) {
      router.push(process.env.NEXT_PUBLIC_CARTURL)
    } else {
      handleOpenLoginModal(true, 'icono carrito')
    }
    setActiveMenu('')
  }

  const handleClickLogo = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'logo_unimarc'
    })
  }

  const handleClickSecondaryLinks = (link) => {
    const locales = {
      'Centro de ayuda': 'centro_ayuda',
      'Club Ahorro': 'club_ahorro',
      Cupones: 'cupones',
      'Locales y horarios': 'locales_horarios',
      Recetas: 'recetas',
      Unipay: 'unipay'
    }

    const localName = link.trim()
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: locales?.[localName]
    })
  }

  const handleClickGoToMyCoupons = async () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'menu_cliente',
      eventLabel: 'mis_cupones'
    })
    tagClickCouponIconInHeader()
    await router.push(process.env.NEXT_PUBLIC_COUPONSURL)
    setActiveMenu('')
  }

  const handleOpenMenu = useCallback(
    (buttonPressed: string) => {
      if (buttonPressed === 'myProfile') {
        handleMyProfileMenuClick()
      } else {
        setActiveMenu(buttonPressed)
      }

      const locales = {
        categories: 'menu_categorias',
        salesOffer: 'boton_ofertas',
        myProfile: 'hola_cliente'
      }

      if (locales?.[buttonPressed])
        datalayerSimpleEvent({
          event: 'interacciones_header',
          eventAction: 'clic',
          eventCategory: 'header',
          eventLabel: locales?.[buttonPressed]
        })
    },
    [isLoggedIn, activeMenu]
  )

  const handleClickRepeatLast = useCallback(() => {
    if (!isLoggedIn) {
      handleOpenLoginModal()
      setActiveMenu('')
    }
  }, [isLoggedIn, activeMenu])

  const handleClosePromoHeader = () => {
    setShowPromoHeader(false)
  }

  const handleClickGoToMyOrders = async () => {
    datalayerSimpleEvent({
      event: 'evento-interactivo',
      eventAction: 'Botón seguimiento de tu pedido',
      eventCategory: 'Interacciones en compra exitosa',
      eventLabel: 'Seguimiento de tu pedido'
    })
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'mis_compras'
    })
    await new Promise((resolve) => {
      return setTimeout(resolve, 1000)
    })
    if (window.location.origin === process.env.NEXT_PUBLIC_MYORDERSURL)
      window.location.reload()
    router.push(process.env.NEXT_PUBLIC_MYORDERSURL)
  }

  const handleOnClickMyShopping = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'mis_compras'
    })
  }
  const handleModalClose = () => {
    setIsOpenModalDetail(false)
    setProductSlug('')
  }

  const handleClickGoToMyLists = async () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'menu_cliente',
      eventLabel: 'mis_listas'
    })
    await router.push(process.env.NEXT_PUBLIC_LISTURL)
    setIsVisibleMyProfile(false)
  }

  const handleClickGoToMyCards = async () => {
    await router.push(process.env.NEXT_PUBLIC_CARDSURL)
    setIsVisibleMyProfile(false)
  }

  const onClickGoToMyPersonalInformation = async () => {
    await router.push(process.env.NEXT_PUBLIC_INFORMATIONURL)
    setIsVisibleMyProfile(false)
  }

  const onClickGoToHelpCenter = async () => {
    await router.push(process.env.NEXT_PUBLIC_HELPCENTER_URL)
    setIsVisibleMyProfile(false)
  }

  const handleClickGoToMyMemberShip = async () => {
    await router.push(process.env.NEXT_PUBLIC_MEMBERSHIP_URL || '/Membership')
    setIsVisibleMyProfile(false)
  }

  const onClickGoToMyRequest = async () => {
    await router.push(
      process.env.NEXT_PUBLIC_MY_REQUESTS_ZENDESK_URL || '/MyRequestsZendesk'
    )
    setIsVisibleMyProfile(false)
  }

  const onClickGoToMyFavorites = async () => {
    await router.push(
      process.env.NEXT_PUBLIC_MY_FAVORITES_URL || '/MyFavorites'
    )
    setIsVisibleMyProfile(false)
  }

  const handleMyProfileMenuClick = () => {
    if (isMobile) {
      router.push(process.env.NEXT_PUBLIC_MYORDERSURL)
    } else {
      setActiveMenu('myProfile')
    }
  }

  const handleMyProfileClick = () => {
    if (isMobile) {
      router.push(process.env.NEXT_PUBLIC_MYORDERSURL)
    } else {
      setIsVisibleMyProfile(!isVisibleMyProfile)
    }
  }
  const handleOnClickProduct = async (
    slug: string,
    item?: ILastSeenProducts
  ) => {
    setProductSlug(slug)
    productClickTag({
      product: item,
      site: 'Unimarc',
      listIdVal: 'SRP_vistos recientemente',
      listNameVal: 'SRP - vistos recientemente',
      isSearch: true
    })
    setIsOpenModalDetail(true)
  }

  const handlePushTagLogin = () => {
    datalayerSimpleEvent({
      event: 'interacciones_header',
      eventAction: 'clic',
      eventCategory: 'header',
      eventLabel: 'inicia_sesion'
    })
  }

  const handleFacetsRefetch = () => {
    trigger({
      eventType: 'refetchFacets'
    })
  }

  // This useEffect monitors changes in the `suggestions` property from the router query.
  // When `suggestions` is present, it updates the component's state to:
  // - Show the "clear" icon (e.g., for resetting the input or suggestions),
  // - Hide DropDownSearch,
  // - Focus the input or an associated field for user interaction.
  useEffect(() => {
    // Check if `suggestions` exists in the router query
    if (router?.query?.suggestions) {
      // Show the "clear" icon
      setIsVisibleClearIcon(true)

      // Hide DropDownSearch
      setIsVisible(false)

      // Set the input or related element to focused
      setIsFocused(true)
    }
  }, [router?.query?.suggestions])

  useEffect(() => {
    if (isVisible) setIsVisible(false)
  }, [router])

  useEffect(() => {
    if (searchParams !== undefined && typeof searchParams === 'string') {
      setValue(
        searchParams?.includes('-')
          ? cleanSearching(searchParams)
          : searchParams
      )
    }
    const handleStart = () => {
      dropDownSearchOpacityOn('dropDownSearchV2__column')
    }

    const handleComplete = () => {
      const element = document.getElementById('dropDownSearchV2__column')
      if (element) {
        element.style.opacity = ''
        element.style.pointerEvents = ''
      }
      setIsVisible(false)
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      if (!router) return
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [router])

  useEffect(() => {
    isValidArrayWithData(resultAisles?.data?.data) &&
      tagMarksClickedCategories({ resultAisles, categoriesDataImage })
  }, [resultAisles?.data?.data])

  useEffect(() => {
    tagMarksCategories({ processedElements, resultAisles, categoriesDataImage })
  }, [resultAisles?.data?.data, categoriesDataImage])

  useEffect(() => {
    if (isVisible === true) {
      datalayerSimpleEvent({
        event: 'search',
        eventCategory: 'busqueda',
        eventAction: 'empezar_busqueda'
      })
    }
  }, [isVisible])

  const modalProps: ModalProps =
    isMobile || device
      ? {
          styleProps: {
            borderRadius: 'var(--border-radius-md) var(--border-radius-md) 0 0',
            padding: '16px 0 0 0'
          },
          iconsProps: {
            customCloseIcon: 'Close' as IconProps['name'],
            customClassNameCloseIcon: styles.modalCloseIcon,
            sizeIconClose: 20,
            iconPadding: '0 16px 10px 16px'
          },
          dragProps: {
            dragRatio: 0.2,
            draggButton: false
          },
          modalConfigsProps: {
            isWindowBlocked: true,
            isOpen: isOpenModalDetail,
            toggle: handleModalClose,
            toggleOutside: handleModalClose,
            openAnimation: true,
            maxWidth: 'max-content',
            fullScreenSticky: true,
            isAutoHeight: true
          }
        }
      : {
          styleProps: {
            height: 'max-content',
            padding: '0'
          },
          iconsProps: {
            iconActionHover: true,
            iconPadding: '20px',
            sizeIconClose: 16
          },
          modalConfigsProps: {
            idList: { rightIcon: 'headerBrowse__productDetailModalCloseIcon' },
            isOpen: isOpenModalDetail,
            maxHeight: '550px',
            maxWidth: '837px',
            toggle: handleModalClose,
            toggleOutside: handleModalClose
          }
        }

  useEvents({
    eventType: 'loginModal',
    callBack: ({ detail: { show, ref } }) => {
      handleOpenLoginModal(show, ref)
    }
  })

  useEvents({
    eventType: 'onClickPillSuggestions',
    callBack: () => {
      setValue(
        searchParams?.includes('-')
          ? cleanSearching(searchParams)
          : searchParams
      )
    }
  })

  useEffect(() => {
    const cleanSearchHistoryWhenProductDataLoads = async () => {
      await sleep(2000)
      queryClient.invalidateQueries('searchHistory')
    }
    cleanSearchHistoryWhenProductDataLoads()
    if (searchParams === undefined) setValue('')
  }, [searchParams])

  useEffect(() => {
    setLoginModalOpen(shouldLoginModalBeOpen)
  }, [shouldLoginModalBeOpen])

  useEffect(() => {
    let slaLabelValue = 'Sin cobertura'

    const validateSLA = async () => {
      setIsCheckingSla(true)
      const localSc = JSON.parse(sessionStorage.getItem('localAddress'))
      const items = Cookies.get('addToCart')
        ? JSON.parse(Cookies.get('addToCart'))
        : []
      const response = await callToPostSlaSimulation({
        selectedAddresses,
        isPickUp: isPickup,
        sc: isLoggedIn
          ? orderForm.data.data.salesChannel
          : localSc.responseData.salesChannel,
        items: isLoggedIn
          ? undefined
          : items.map((item) => {
              return {
                quantity: item.quantity,
                seller: '1',
                id: item.id
              }
            })
      })

      setLocalSla(response)
      updateCurierName({
        hadCalledSimulation: true,
        courierName:
          response?.data?.logisticsInfo?.[0]?.slas?.[0]?.deliveryIds?.[0]
            ?.courierName ?? ''
      })

      const slaValue = validateSlaValueExist(response)

      if (slaValue?.startDateUtc) {
        const { day, month, hourMinutes24, shortDayName } = formatDate({
          date: slaValue?.startDateUtc
        })

        const slaPrice = isPickup
          ? 'Gratis'
          : `${currencyFormat({ number: slaValue?.lisPrice })}`
        slaLabelValue = `Desde: ${
          shortDayName.charAt(0).toUpperCase() + shortDayName.slice(1)
        } ${day}/${month}, ${hourMinutes24} hrs - ${slaPrice}`
      }
      if (sla != slaLabelValue) setSla(slaLabelValue)
      isLoggedIn &&
        (await validateSelectedAddressExist({
          selectedAddresses,
          orderForm,
          isOrderFormLoading
        }))
      setIsCheckingSla(!response.error)
    }

    if (
      (!orderForm.isLoading &&
        orderForm?.data?.data?.clientProfileData?.email &&
        (sla == '' || sla == slaLabelValue)) ||
      (hasLocalAddress && !isCheckingSla && selectedAddresses.addressId)
    )
      validateSLA()
  }, [
    selectedAddresses,
    orderForm?.isFetched,
    orderForm.isLoading,
    hasLocalAddress,
    localQueryOrderForm,
    orderForm.isLoading
  ])

  useEffect(() => {
    if (!userProfileId && localStorage.getItem('userProfileId'))
      localStorage.removeItem('userProfileId')
    if (userProfileId) localStorage.setItem('userProfileId', userProfileId)
  }, [userProfileId])

  useEffect(() => {
    const subtotal = cartData?.subtotal !== '$0' ? cartData?.subtotal : ''
    setTotal(subtotal)
  }, [cartData?.subtotal])

  useEffect(() => {
    !isLoggedIn && userFirstNameCached.clearCache()
  }, [isLoggedIn])

  useEffect(() => {
    if (
      dataMembership?.error === 'not found' ||
      dataMembership?.status === 404 ||
      dataMembership?.status === 400 ||
      dataMembership?.status.toString() === '404' ||
      dataMembership?.status.toString() === '400' ||
      (!dataMembership?.membership && !isLoadingMembership)
    ) {
      setShowMembershipMenu(false)
    }
  }, [dataMembership, isLoadingMembership])

  return (
    <ResponsiveContext.Provider value={{ width: withProvider }}>
      <LoginModalBrowse
        isOpen={loginModalOpen}
        onClose={handleCloseLoginModal}
        router={router}
        tagLoginRef={tagLoginRef}
      />
      {isOpenModalDetail && (
        <ProductDetailModalWrapper
          columnSearchRef={columnSearchRef}
          handleModalClose={handleModalClose} //for similar modals
          isMobile={isMobile || device}
          isSearch={true}
          modalProps={modalProps}
          productCouponModal={{}}
          productSlug={productSlug}
        />
      )}

      <Container
        id='header__browse'
        isWrap
        position='static'
        ref={headerContainer}
        zIndex={getGlobalStyle('--z-index-10')}
      >
        {children}
        <HeaderPrototype
          activeColor={activeButtonColor}
          activeMenu={activeMenu}
          addressSelected={addressSelected || 'Selecciona tu dirección'}
          backgroundDropDownSearch={backgroundDropDownSearch}
          backgroundStoreLocatorMobile={backgroundStoreLocatorMobile}
          categoriesData={{ ...aislesPropsData }}
          cfDataHeader={cfDataHeader}
          colorBackgroundNumberCartIcon={colorBackgroundNumberCartIcon}
          colorBackgroundSearch={colorBackgroundSearch}
          colorIconClosePromo={colorIconClosePromo}
          colorLogoHeader={colorLogoHeader}
          colorQuantity={colorNumberCartIcon}
          colorStoreLocator={colorStoreLocator}
          colorTextHeaderPrincipal={colorTextHeaderPrincipal}
          colorTextLinkPromo={colorTextLinkPromo}
          colorTextPromo={colorTextPromo}
          colorTextSearch={colorTextSearch}
          colorTextSecondaryHeader={colorTextSecondaryHeader}
          columnSearchRef={columnSearchRef}
          containerRef={headerContainer}
          handleClosePromoHeader={handleClosePromoHeader}
          handleFacetsRefetch={handleFacetsRefetch}
          handleOnClickProduct={handleOnClickProduct}
          handleOpenMenu={handleOpenMenu}
          handlePushTagLogin={handlePushTagLogin}
          hasAddress={hasLocalAddress || isLoggedIn}
          hidePromoAndSecondaryHeader={hidePromoAndSecondaryHeader}
          hoverTextSecondaryHeader={hoverTextSecondaryHeader}
          hoversHeaderButtons={hoversHeaderButtons}
          iconStoreLocator={iconStoreLocator}
          imageModalLogout={imageModalLogout}
          isDownloadToastOpen={isDownloadToastOpen}
          isLoggedIn={isLoggedIn}
          isMobile={isMobile || device}
          isOpenModalDetail={isOpenModalDetail}
          isPickup={isPickup}
          isVisibleModalLogout={isVisibleModalLogout}
          linkWrapper={Link}
          logo='LogoHeaderUnimarcCL'
          offersLinks={offersLinks}
          onClickCart={handleClickCart}
          onClickCloseLogoutMobile={() => {
            setIsVisibleModalLogout(false)
          }}
          onClickCloseMyProfile={handleClose}
          onClickGoToHelpCenter={onClickGoToHelpCenter}
          onClickGoToMyCards={handleClickGoToMyCards}
          onClickGoToMyCoupons={handleClickGoToMyCoupons}
          onClickGoToMyFavorites={onClickGoToMyFavorites}
          onClickGoToMyLists={handleClickGoToMyLists}
          onClickGoToMyMemberShip={handleClickGoToMyMemberShip}
          onClickGoToMyOrders={handleClickGoToMyOrders}
          onClickGoToMyPersonalInformation={onClickGoToMyPersonalInformation}
          onClickGoToMyRequest={onClickGoToMyRequest}
          onClickLogin={() => {
            return handleOpenLoginModal(true, 'boton-ingresar')
          }}
          onClickLogo={handleClickLogo}
          onClickLogout={handleLogout}
          onClickLogoutMobile={() => {
            setIsVisibleModalLogout(true)
          }}
          onClickMyProfile={handleMyProfileClick}
          onClickMyShopping={handleOnClickMyShopping}
          onClickRepeatLastOrder={handleClickRepeatLast}
          onClickSecondaryLinks={handleClickSecondaryLinks}
          onClickStoreLocator={handleStoreLocator}
          primaryBackground={backgroundColorPrimary}
          profilePrependSlot={<HeaderMembership />}
          promoBackground={backgroundColorPromo}
          promoContent={promoContent}
          promoHeader={showPromoBanner}
          promoLinkText={promoLinkText}
          promoLinkUrl={promoLinkUrl}
          quantity={useUpdateProps.totalQuantity}
          quantityLoading={isOrderFormLoading}
          router={router}
          searchProps={{ ...searchPropsData, isFocusedInput: isFocused }}
          secondaryBackground={backgroundColorSecondary}
          secondaryLinks={secondaryHeaderLinks}
          setIsVisibleClearIcon={setIsVisibleClearIcon}
          showMembershipMenu={showMembershipMenu}
          showPromoHeader={showPromoHeader}
          site='unimarc'
          smallScreenSLVisible={smallScreenSLVisible}
          source={router.asPath}
          timeTable={sla}
          total={total}
          urlLogo={goToHome()}
          userName={userFirstNameCached.value}
        />
        <SSOWelcomeModal />
        <HeaderClubBenefitsModal />
        <HeaderClubSavingModal />
        <BigScreen>
          {(activeMenu || isVisible) && <Overlay onClick={handleClose} />}
        </BigScreen>
      </Container>
    </ResponsiveContext.Provider>
  )
}
