import React, { useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import Image from 'next/future/image'
import {
  useProdutsCarousel,
  useShelvesData
} from '../../ProductsCarousel/helpers/useProdutsCarousel'
import {
  useUpdateFromShelf,
  useEvents,
  visiblePromos,
  addToHomeData,
  useProductsSearch
} from '@smu-chile/pkg-unimarc-hooks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import {
  BackboneShelves,
  Column,
  Divider,
  Row,
  ShelfCarousel,
  Title
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { IProductInList } from '@smu-chile/pkg-unimarc-components/shared/interfaces/ILists'
import { useLocalAddress } from 'shared/utils/hooks/useLocalAddress'

export interface ProductDetailCarrouselProps {
  categoryId: string
  isMobile?: boolean
  customItemToSlide?: number
  customItemToShow?: number
  ean?: string
  handleOpenMyListsModal?: (item: IProductInList) => void
  onClickProduct?: () => void
}
export const ProductDetailCarouselComponent = ({
  categoryId,
  isMobile,
  customItemToSlide,
  customItemToShow,
  handleOpenMyListsModal,
  ean,
  onClickProduct
}: ProductDetailCarrouselProps) => {
  const [isOrderFormLoading, setIsOrderFormLoading] = useState(false)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const { hasLocalAddress } = useLocalAddress()
  const carouselRef = useRef(null)
  const carouselView = useRef(0)
  const listId = 'productos_similares'
  const listName = 'PDP - Productos Similares'
  const site = 'Unimarc'

  const productsN3 = useProductsSearch({
    categories: categoryId,
    from: '0',
    to: '11',
    reactQuery: {
      queryKey: categoryId,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const handlePDPShelf = ({ ref, shelf }) => {
    addToHomeData({
      ref,
      promoData: { ...shelf, promoTitle: '' },
      isShelf: true
    })
  }

  const { availableProducts = [] } = productsN3?.data || {}
  const products = availableProducts?.filter((product) => {
    return product?.['ean'] !== ean
  })
  const {
    dataPromotions,
    positionContainer,
    itemsToShow,
    itemsToSlide,
    spacerBelow,
    resultPromotions
  } = useProdutsCarousel({ isMobile })

  const {
    shelvesData,
    editTempOrderForm,
    handleAdd,
    handleChange,
    handleOnClick,
    handleRemove
  } = useUpdateFromShelf({
    isOrderFormLoading,
    products,
    isProductCarousel: true,
    hasLocalAddress
  })

  const productsMemo =
    useShelvesData(shelvesData, dataPromotions, isMobile) || []
  //When the event is called this function there go to read all items that will update and set a new loading state for each item
  useEvents({
    eventType: 'loadingOrderForm',
    callBack: ({ detail: { loading, items } }) => {
      setIsOrderFormLoading(loading)
      editTempOrderForm(items, loading)
    }
  })

  const carouselShelfData = {
    positionContainer,
    productsMemo,
    itemsToShow: customItemToShow || itemsToShow,
    itemsToSlide: customItemToSlide || itemsToSlide,
    spacerBelow,
    handleOnClick,
    handleAdd,
    handleRemove,
    handleChange
  }

  const isLoading: boolean =
    productsN3.isLoading ||
    Boolean(resultPromotions?.isLoading) ||
    Boolean(dataPromotions?.isLoading)

  const handleIntersect = (entries) => {
    const [entry] = entries
    setIsIntersecting(entry.isIntersecting)
  }

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, options)
    if (carouselRef.current) observer.observe(carouselRef.current)
    carouselView.current += 1
    return () => {
      if (carouselRef.current && carouselView.current === 1)
        observer.unobserve(carouselRef.current)
    }
  }, [carouselRef, options])

  if (isLoading) {
    return (
      <BackboneShelves
        shelvesQuantityDesktop={5}
        shelvesQuantityMobile={2}
      />
    )
  }

  if (productsMemo?.length === 0) {
    return null
  }

  return (
    <>
      {Array.isArray(products) && products?.length > 0 && (
        <Row isWrap>
          {isMobile ? (
            <Column padding='0 20px 0 10px'>
              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={26}
              />
              <Title
                color={getGlobalStyle('--color-text-black')}
                customFontSize='16px'
                fontWeight='medium'
                headingLevel='h4'
                text='Productos similares'
              />
            </Column>
          ) : (
            <>
              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={26}
              />
              <Title
                color={getGlobalStyle('--color-text-black')}
                customFontSize='16px'
                fontWeight='medium'
                headingLevel='h4'
                text='Productos similares'
              />
              <Divider
                borderHeight='3xs'
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={24}
              />
            </>
          )}
          <Row
            justifyContent='center'
            margin='0 0 12px'
            maxWidth='1120px'
          >
            <Column>
              <Row
                padding='10px'
                ref={carouselRef}
              >
                {isIntersecting &&
                  visiblePromos({
                    site: 'Unimarc',
                    listIdVal: 'productos_similares',
                    listNameVal: 'PDP - Productos Similares'
                  })}
                <ShelfCarousel
                  carouselProps={{
                    infinite: true,
                    autoPlay: false,
                    shouldResetAutoplay: false,
                    afterChange: () => {
                      return visiblePromos({
                        site,
                        listIdVal: listId,
                        listNameVal: listName
                      })
                    },
                    partialVisible: true
                  }}
                  catchShelfRef={handlePDPShelf}
                  containerProps={carouselShelfData.positionContainer}
                  handleOnClick={carouselShelfData.handleOnClick}
                  handleOpenMyListsModal={handleOpenMyListsModal}
                  isMobile={isMobile}
                  items={carouselShelfData.productsMemo}
                  itemsToShow={carouselShelfData.itemsToShow}
                  linkWrapper={Link}
                  nextImage={Image}
                  onClickProduct={onClickProduct}
                  quantityButtonProps={{
                    handleAdd: carouselShelfData.handleAdd,
                    handleRemove: carouselShelfData.handleRemove,
                    handleChange: carouselShelfData.handleChange
                  }}
                  quantityButtonStyles={{
                    fontSize: 'md',
                    margin: '0px',
                    maxHeight: '30px',
                    maxWidth: '163px',
                    sizeCircle: 22
                  }}
                  showAddToListButton={true}
                  showArrows={!isMobile}
                  showCouponButton={false}
                  slidesToSlide={carouselShelfData.itemsToSlide}
                />
              </Row>
            </Column>
          </Row>
        </Row>
      )}
    </>
  )
}

function handleCarousel(prevState, nextState) {
  return prevState.categoryId === nextState.categoryId
}
export const ProductDetailCarousel = React.memo(
  ProductDetailCarouselComponent,
  handleCarousel
)
