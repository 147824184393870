import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import {
  UseQueryResult,
  clickedPromos,
  datalayerSimpleEvent
} from '@smu-chile/pkg-unimarc-hooks'
import { ICategories } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/ICategories'

interface TagMarksCategoriesProps {
  processedElements: Set<unknown>
  resultAisles: UseQueryResult<ICategories>
  categoriesDataImage: Array<object>
}

interface TagMarksClickedCategoriesProps {
  resultAisles: UseQueryResult<ICategories>
  categoriesDataImage: Array<object>
}

export const tagMarksCategories = ({
  processedElements,
  resultAisles,
  categoriesDataImage
}: TagMarksCategoriesProps) => {
  const parentContainer = getBemId('categoriesSearch', 'parentContainer')
  const categoriesButton = getBemId(
    'categoriesSearch',
    'container',
    'categorybutton'
  )

  const findMatchingNode = (nodes, nameToMatch) => {
    if (!Array.isArray(nodes)) return null

    for (const node of nodes) {
      if (node.name === nameToMatch) return node
      if (Array.isArray(node.children) && node.children.length > 0) {
        const result = findMatchingNode(node.children, nameToMatch)
        if (result) return result
      }
    }
    return null
  }

  const handleIntersection = (entries) => {
    const newVisibleItems = []
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const intersectingElement = entry.target
        if (!processedElements.has(intersectingElement)) {
          processedElements.add(intersectingElement)
          const data = processElement(intersectingElement)
          if (data) newVisibleItems.push(data)
        }
      }
    })
    if (newVisibleItems.length > 0) {
      datalayerSimpleEvent({
        event: 'view_promotion',
        ecommerce: {
          items: newVisibleItems
        }
      })
    }
  }

  const processElement = (element) => {
    const labelElement = element.querySelector(
      `#${getBemId('categoriesSearch_container', 'categoryButton', 'label')}`
    )
    if (labelElement) {
      const labelValue = labelElement.value || labelElement.textContent || ''

      const matchingObject = findMatchingNode(
        resultAisles?.data?.data,
        labelValue
      )

      if (matchingObject) {
        let url = matchingObject.url
        if (/\.com\.br(.+)/.test(url)) {
          url = `/category` + /\.com\.br(.+)/.exec(url)[1]
        }
        const categoryDataImage = categoriesDataImage?.find(
          ({ idCategory }: { idCategory: string }) => {
            return idCategory === matchingObject.id
          }
        )

        return {
          href: url,
          image: categoryDataImage?.['categoryImage']?.file?.url,
          name: matchingObject?.name,
          creativeName: 'SEARCH - Capsulas Categorías',
          creativeSlot: matchingObject?.id,
          promotionId: matchingObject?.fqDescriptionSlug,
          promotionName: matchingObject?.name,
          urlPath: url,
          locationId: `1 - ${matchingObject?.id}`
        }
      }
    }
    return null
  }

  const parentElement = document.querySelector(`#${parentContainer}`)
  const observer = new IntersectionObserver(handleIntersection, {
    root: parentElement,
    threshold: 0.1
  })

  const elements = document.querySelectorAll(`[id*=${categoriesButton}]`)
  elements.forEach((element) => {
    observer.observe(element)
  })

  return () => {
    elements.forEach((element) => {
      observer.unobserve(element)
    })
  }
}

export const tagMarksClickedCategories = ({
  resultAisles,
  categoriesDataImage
}: TagMarksClickedCategoriesProps) => {
  const handleClick = (event) => {
    const categorie = getBemId(
      'categoriesSearch',
      'container',
      'categoryButton'
    )
    const clickedElement = event.target.closest(`[id*="${categorie}"]`)
    if (clickedElement) {
      // Search for the internal element inside clickedElement
      const labelElement = clickedElement.querySelector(
        `#${getBemId('categoriesSearch_container', 'categoryButton', 'label')}`
      )

      if (labelElement) {
        // Get the value from labelElement
        const labelValue = labelElement.value || labelElement.textContent || ''

        // Recursive function to search the structure
        const findMatchingNode = (nodes, nameToMatch) => {
          if (!Array.isArray(nodes)) return null // Ensure nodes is an array

          for (const node of nodes) {
            if (node.name === nameToMatch) {
              return node // Match found
            }
            if (Array.isArray(node.children) && node.children.length > 0) {
              const result = findMatchingNode(node.children, nameToMatch)
              if (result) {
                return result
              }
            }
          }
          return null // No match found
        }
        // Perform the search in resultAisles.data
        const matchingObject = findMatchingNode(
          resultAisles?.data?.data,
          labelValue
        )

        if (matchingObject) {
          let url = matchingObject.url
          if (/\.com\.br(.+)/.test(url)) {
            url = `/category` + /\.com\.br(.+)/.exec(url)[1]
          }
          const categoryDataImage = categoriesDataImage?.find(
            ({ idCategory }: { idCategory: string }) => {
              return idCategory === matchingObject.id
            }
          )
          const categoriesTagData = {
            href: url,
            image: categoryDataImage?.['categoryImage']?.file?.url,
            name: matchingObject?.name,
            creativeName: 'SEARCH - Capsulas Categorías',
            creativeSlot: matchingObject?.id,
            promotionId: matchingObject?.fqDescriptionSlug,
            promotionName: matchingObject?.name,
            urlPath: url,
            locationId: `1 - ${matchingObject?.id}`
          }
          clickedPromos(categoriesTagData) // Use the found object
        }
      }
    }
  }

  document.addEventListener('click', handleClick)
  return () => {
    document.removeEventListener('click', handleClick)
  }
}
