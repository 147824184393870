import { postNewAddress, useUpdateAddress } from '@smu-chile/pkg-unimarc-hooks'

/**
 * Update user addresses to the one saved on localSession and remove it from it an then reload the page
 * @param { Data } data The data from useOrderForm hook
 * @param { NextRouter } router The router used in the component uses to reload the page
 */
export const UpdateAddressFromLocal = async (data, router) => {
  const { handleAddressObject } = useUpdateAddress(router)
  const localAddress = JSON.parse(sessionStorage.getItem('localAddress'))
  const {
    data: {
      userProfileId,
      clientProfileData: { firstName, lastName }
    }
  } = data

  const responseNewAddress = await postNewAddress({
    city: localAddress.data.city,
    complement: localAddress.data.complement,
    country: localAddress.data.country,
    geoCoordinate: localAddress.data.geoCoordinate,
    neighborhood: localAddress.data.neighborhood,
    number: localAddress.data.number,
    placeId: localAddress.data.plaeId,
    postalCode: localAddress.data.postalCode,
    receiverName: `${firstName} ${lastName}`,
    reference: localAddress.data.reference,
    state: localAddress.data.state,
    street: localAddress.data.street,
    userId: userProfileId
  })
  const isError = responseNewAddress?.data['error']

  if (
    typeof isError === 'boolean' &&
    !isError &&
    localAddress?.data?.addressType === 'residential'
  ) {
    handleAddressObject({
      addressId: responseNewAddress.data['data'].addressName,
      addressType: responseNewAddress.data['data'].addressType,
      city: localAddress.data.city,
      complement: localAddress.data.complement || '',
      country: localAddress.data.country,
      geoCoordinates: localAddress.data.geoCoordinarte,
      neighborhood: localAddress.data.neighborhood,
      number: localAddress.data.number,
      reference: localAddress.data.reference,
      state: localAddress.data.state,
      street:
        localAddress.data.street.length === 0
          ? localAddress.data.state
          : localAddress.data.street,
      salesChannel: localAddress.responseData.salesChannel
    })
    sessionStorage.removeItem('localAddress')
    router.reload()
    return
  }
}
