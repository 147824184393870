import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  DesktopModalWrapper,
  MembershipHeader,
  MobileModalWrapper,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import { CarouselBanners } from '@smu-chile/pkg-unimarc-components/stories/organisms/Banners/CarouselBanners'
import { currencyFormat, sleep, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import {
  setMembershipButtons,
  setMembershipData,
  setNextMembershipCycle
} from 'shared/helpers/membershipHeader'
import {
  IMembershipDataCF,
  IUserClubUnimarc
} from 'shared/interfaces/IMembership'
import styles from 'styles/MembershipHeaderBrowse.module.css'

interface MembershipHeaderBrowseProps {
  membershipDataCF?: IMembershipDataCF
  userData?: IUserClubUnimarc
  goalType?: string
  levelGoal?: string
  marginTop?: string
  shoppingGoal?: string
  totalSavings?: number
}

export const MembershipHeaderBrowse = ({
  goalType,
  levelGoal,
  marginTop,
  membershipDataCF,
  shoppingGoal,
  totalSavings,
  userData
}: MembershipHeaderBrowseProps) => {
  const router = useRouter()
  const { isMobile } = useMobile()
  const [isOpen, setIsOpen] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [membershipUserData, setMembershipUserData] = useState(null)
  const modalHeight = modalType === 'ahorro' ? 'none' : '442px'
  const mobileModalHeight = modalType === 'ahorro' ? '396px' : '532px'

  const accountNumber = currencyFormat({
    number: totalSavings ?? 0,
    bool: false
  })

  const banners = membershipDataCF?.imageLevels?.map((banner) => {
    return { img: `https:${banner?.file?.url}` }
  })

  const handleOpenModal = (buttonLabel: string, url: string) => {
    if (buttonLabel?.includes('ahorr')) {
      setModalType('ahorro')
      setIsOpen(true)
    } else if (buttonLabel?.includes('niveles')) {
      setModalType('beneficio')
      setIsOpen(true)
    } else if (url) {
      router.push(`${router.route}${url}`)
    } else {
      setIsOpen(false)
    }
  }

  const handleClickModalButton = () => {
    if (modalType === 'ahorro' && userData?.level !== 'clubunimarc') {
      router.push('/club-unimarc/beneficios')
    } else {
      setIsOpen(false)
    }
  }

  const handleCloseModal = async () => {
    const benefitsModal = document.getElementById('benefits__container')

    if (benefitsModal) {
      benefitsModal.classList.remove(styles.openModal)
      benefitsModal.classList.add(styles.closeModal)
      await sleep(500)
    }
    setIsOpen(false)
  }

  useEffect(() => {
    setMembershipUserData(setMembershipData(membershipDataCF?.membershipLevels))
  }, [membershipDataCF, userData])

  useEffect(() => {
    if (modalType === 'beneficio' && isOpen) {
      const benefitsModal = document.getElementById('benefits__container')
      if (benefitsModal) {
        benefitsModal.classList.add(styles.openModal)
        benefitsModal.classList.remove(styles.closeModal)
      }
    }
  }, [modalType, isOpen])

  return (
    <>
      <MembershipHeader
        bodyHeight={isMobile ? '327px' : '254px'}
        buttons={setMembershipButtons(
          membershipDataCF?.headerButtons?.items,
          accountNumber,
          handleOpenModal
        )}
        buttonsAligment='center'
        endDate={setNextMembershipCycle()}
        fontColor={membershipUserData?.fontColor}
        goalImage={membershipUserData?.goalImage}
        goalType={goalType}
        isMobile={isMobile}
        levelGoal={levelGoal}
        limitType={isMobile ? 'inner' : 'outer'}
        mainBackgroundColor={membershipUserData?.backgroundColor}
        mainContentAlign='center'
        marginTop={marginTop}
        progressBar={membershipUserData?.progressBar}
        secondaryBackgroundColor={membershipUserData?.secondaryBackgroundColor}
        shoppingGoal={shoppingGoal}
        type={isMobile ? 'full' : 'half'}
        userIcon={membershipUserData?.img}
        userLevel={membershipUserData?.userLevel}
        userName={userData?.firstName}
      />
      {modalType === 'ahorro' && (
        <DesktopModalWrapper
          blockId={getBemId('membership', 'modal')}
          bodyChildrenProps={{
            minHeight: isMobile ? mobileModalHeight : modalHeight,
            maxHeight: '500px'
          }}
          borderEdge='none'
          footerChildrenProps={{
            default: true,
            buttonDefaultProps: {
              label:
                userData?.level === 'clubunimarc'
                  ? 'Entendido'
                  : 'Ver panoramas'
            },
            onClick: handleClickModalButton
          }}
          hasfooter={true}
          headerCloseIcon={{
            default: true,
            name: 'CloseThin',
            sizes: '2xs'
          }}
          headerTitle={null}
          modalConfigsProps={{
            isOpen,
            toggle: handleCloseModal,
            toggleOutside: handleCloseModal,
            minHeight: '500px',
            fullScreen: isMobile,
            minHeightFullScreen: '500px',
            marginFullScreen:
              modalType === 'ahorro'
                ? 'calc(100vh - 520px) 0 0'
                : 'calc(100vh - 590px) 0 0'
          }}
          styleProps={{
            height: isMobile ? '520px' : '500px'
          }}
        >
          <Column
            alignItems='center'
            gap='18px'
            margin='-20px 0 0'
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              id={getBemId('modal', 'title', 'ahorro')}
            >
              ¡Felicidades!
            </Text>
            <Container id={getBemId('modal', 'image', 'ahorro')}>
              <Image
                altPicture='Club Unimarc image'
                mobileCustomSize='246px'
                srcPicture={membershipDataCF?.imageSaving}
              />
            </Container>
            <Text
              id={getBemId('modal', 'text', 'ahorro')}
              textAlign='center'
            >
              Haz ahorrado {accountNumber} en tus compras realizadas en las
              tiendas de Unimarc, app y web.
            </Text>
          </Column>
        </DesktopModalWrapper>
      )}
      {modalType === 'beneficio' && (
        <>
          <BigScreen>
            <DesktopModalWrapper
              blockId={getBemId('benefits', 'modal')}
              bodyChildrenProps={{
                minHeight: isMobile ? mobileModalHeight : modalHeight,
                maxHeight: '100vh',
                overflow: 'hidden'
              }}
              borderEdge='bottom'
              footerChildrenProps={{
                default: false,
                buttonDefaultProps: {
                  label:
                    userData?.level === 'clubunimarc'
                      ? 'Entendido'
                      : 'Ver panoramas'
                },
                onClick: handleClickModalButton
              }}
              hasfooter={false}
              headerCloseIcon={{
                default: true,
                name: 'CloseThin',
                sizes: '2xs'
              }}
              headerTitle={'Beneficios Niveles'}
              modalConfigsProps={{
                containerId: getBemId('benefits', 'container'),
                customContainerClassName: styles.modalContainer,
                isOpen,
                minHeight: '95vh',
                toggle: handleCloseModal,
                toggleOutside: handleCloseModal,
                isWindowBlocked: true
              }}
            >
              <Container
                style={{
                  padding: '0px 0px 0px 20px'
                }}
              >
                <CarouselBanners
                  banners={[...banners]}
                  infinite={false}
                  itemsToShow={1}
                  minHeight={isMobile ? '600px' : '500px'}
                  minWidthMobile='100%'
                  showDots={true}
                />
              </Container>
            </DesktopModalWrapper>
          </BigScreen>
          <SmallScreen>
            <MobileModalWrapper
              blockId={getBemId('benefitsMobile', 'modal')}
              body={
                <Container>
                  <CarouselBanners
                    banners={[...banners]}
                    infinite={false}
                    itemsToShow={1}
                    minHeight={isMobile ? '600px' : '500px'}
                    minWidthMobile='100%'
                    showDots={true}
                  />
                </Container>
              }
              bodyContainerProps={{
                customClassName: styles['modalBalanceInquiryMobile__body'],
                overflow: 'visible'
              }}
              dragProps={{
                draggButton: false
              }}
              hasFooter={false}
              headerCloseIconProps={{
                customSize: 13
              }}
              headerProps={{
                padding: '3px 16px'
              }}
              headerTitle='Beneficios niveles'
              hideHeaderDivider={false}
              iconColor={getGlobalStyle('--color-base-black')}
              iconSize={16}
              isEnableButton
              modalConfigsProps={{
                openAnimation: true,
                fullScreenSticky: true,
                hiddenX: true,
                isAutoHeight: true,
                isOpen,
                isWindowBlocked: true,
                marginFullScreen: '1vh 0 0',
                stickyBottom: true,
                toggle: handleCloseModal,
                toggleOutside: handleCloseModal
              }}
              onClose={handleCloseModal}
              styleProps={{
                padding: '0',
                height: '100%',
                borderRadius: `${getGlobalStyle(
                  '--border-radius-md'
                )} ${getGlobalStyle('--border-radius-md')} 0 0`
              }}
            />
          </SmallScreen>
        </>
      )}
    </>
  )
}
