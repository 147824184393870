import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { dropDownSearchOpacityOn } from 'shared/helpers/dropDownSearchOpacityOn'

/**
 * Custom hook to handle route changes and manage the opacity and pointer events of an element.
 *
 * This hook listens to Next.js router events (`routeChangeStart`, `routeChangeComplete`, `routeChangeError`)
 * and browser history events (`popstate`) to control the styles of an element with the id
 * `searchBodyProduct_column`.
 *
 * @example
 * // Import and use in a functional React component
 * import useHandleRouteChanges from './path/to/useHandleRouteChanges';
 *
 * const Component = () => {
 *   useHandleRouteChanges();
 *   return <div>My Component</div>;
 * };
 */
const SEARCH_BODY_PRODUCT_COLUMN_ID = 'searchBodyProduct_column'
export const useHandleRouteChanges = () => {
  const router = useRouter()

  useEffect(() => {
    const handleStart = () => {
      const element = document.getElementById(SEARCH_BODY_PRODUCT_COLUMN_ID)
      if (element) {
        dropDownSearchOpacityOn(SEARCH_BODY_PRODUCT_COLUMN_ID)
      }
    }

    const handleComplete = () => {
      const element = document.getElementById(SEARCH_BODY_PRODUCT_COLUMN_ID)
      if (element) {
        element.style.opacity = ''
        element.style.pointerEvents = ''
      }
    }

    const handlePopState = () => {
      // Ensure to reset styles even if the element is missing
      const element = document.getElementById(SEARCH_BODY_PRODUCT_COLUMN_ID)
      if (element) {
        element.style.opacity = ''
        element.style.pointerEvents = ''
      }
    }

    // Add Next.js router events
    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    // Add popstate listener for browser back/forward gestures
    window.addEventListener('popstate', handlePopState)

    return () => {
      // Clean up event listeners
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
      window.removeEventListener('popstate', handlePopState)
    }
  }, [router])
}
