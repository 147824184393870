import { useRouter } from 'next/router'
import { useEffect } from 'react'

const NOT_LOGGED_PARAM = 'login'

export const useValidateLoginParam = (isLoggedIn) => {
  const router = useRouter()

  useEffect(() => {
    if (typeof window === 'undefined') return

    const url = new URL(window.location.href)

    if (isLoggedIn && url.searchParams.has(NOT_LOGGED_PARAM)) {
      url.searchParams.delete(NOT_LOGGED_PARAM)

      if (url.toString() !== window.location.href) {
        router.replace(url.toString())
      }
    }
  }, [isLoggedIn, router])
}
