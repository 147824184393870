import { Modal } from '@smu-chile/pkg-unimarc-components'
import { ModalProps } from '@smu-chile/pkg-unimarc-components/stories/molecules/Modal/helpers'
import { CouponModalProps } from 'components'
import { ProductDetailModal } from 'components/ProductDetailModal/ProductDetailModal/ProductDetailModal'
import router from 'next/router'

export const ProductDetailModalWrapper = ({
  modalProps,
  columnSearchRef,
  isMobile,
  isSearch,
  productCouponModal,
  productSlug,
  handleModalClose
}: {
  columnSearchRef?: React.RefObject<HTMLDivElement>
  modalProps: ModalProps
  isMobile: boolean
  isSearch?: boolean
  productCouponModal: CouponModalProps
  productSlug: string
  handleModalClose: () => void
}) => {
  return (
    <Modal {...modalProps}>
      <ProductDetailModal
        columnSearchRef={columnSearchRef}
        coupon={productCouponModal}
        handleModalClose={handleModalClose}
        isMobile={isMobile}
        isSearch={isSearch}
        productSlug={productSlug}
        text={router?.query?.q}
      />
    </Modal>
  )
}
