/**
 * Adjusts the opacity and disables pointer events for an element with the ID `dropDownSearchV2__column`.
 *
 * This function sets the element's opacity to `0.4` and disables pointer events, effectively making the element semi-transparent and non-interactive.
 *
 * @example
 * // Assume there is an element with ID `dropDownSearchV2__column` in the DOM:
 * <div id="dropDownSearchV2__column"></div>
 *
 * dropDownSearchOpacityOn()
 * // The element will now have `opacity: 0.4` and `pointer-events: none`.
 */
export const dropDownSearchOpacityOn = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.style.opacity = '0.4'
    element.style.pointerEvents = 'none'
  }
}
