import { ClubBlockIds } from 'components/ClubPage/components/blocks/ClubBlock'
import { IContentfulResponse } from '@smu-chile/pkg-unimarc-hooks'
import { IUserWithMembershipResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IGetUserWithMembership'

const MEMBERSHIP_LEVELS_BLOCK_IDS = {
  clubunimarc: 'Socio Club',
  diamante: 'Diamante',
  socioDiamante: 'Socio Diamante',
  oro: 'Socio Oro',
  platino: 'Socio Platino',
  default: 'Sin nivel'
}

export interface ITransformMembershipDataCF {
  infoBlocks: any
  components: any
  imageSaving: any
  imageLevels: IContentfulResponse
  userMembership: IUserWithMembershipResponse
}

export const transformMembershipDataCF = ({
  infoBlocks,
  components,
  imageSaving,
  imageLevels,
  userMembership
}: ITransformMembershipDataCF) => {
  const dataLevelUser = infoBlocks?.[0]?.items?.filter((filteredItem) => {
    const blockId = MEMBERSHIP_LEVELS_BLOCK_IDS[userMembership?.level]
    return filteredItem?.blockId
      ?.toString()
      .toLowerCase()
      .includes(blockId?.toString().toLowerCase())
  })

  const membershipButtons = components
    ?.filter((item) => {
      return (
        item?.blockId === ClubBlockIds.MembershipButtons ||
        item?.blockId === ClubBlockIds.BotonesMembresia
      )
    })?.[0]
    ?.items?.filter((item) => {
      if (userMembership?.membership) {
        if (userMembership?.membership !== 'active') {
          return item?.['label'] === 'Renovar'
        }
      } else {
        return item?.['label'] === 'Ser diamante'
      }

      return false
    })?.[0]

  const levels = (
    imageLevels?.items?.[0]?.fields?.imageBanner as { sys: { id: string } }[]
  )?.map((item) => {
    const entry = imageLevels?.includes?.Entry?.filter((filteredItem) => {
      return filteredItem?.sys?.id === item?.sys?.id
    })?.[0]?.fields as { image: { sys: { id: string } } }
    return imageLevels?.includes?.Asset?.filter((asset) => {
      return entry?.image?.sys?.id === asset?.sys?.id
    })?.[0]?.fields
  })

  return {
    membershipLevels: dataLevelUser || null,
    headerButtons: infoBlocks?.[1] || null,
    membershipButtons: membershipButtons || null,
    imageSaving: imageSaving?.['items'][0]?.fields?.file?.url || null,
    imageLevels: levels || null
  }
}

export const setMembershipData = (data) => {
  return {
    fontColor: data?.[0]?.fields?.fontColor,
    backgroundColor: data?.[0]?.fields?.backgroundColor,
    secondaryBackgroundColor: data?.[0]?.fields?.secondbackgorundcolor,
    img: data?.[0]?.fields?.items?.[0]?.image?.fields?.file?.url,
    imgLabel: data?.[0]?.blockId,
    userLevel: data?.[0]?.description || null,
    progressBar: data?.[0]?.fields?.items?.[1]?.image?.fields?.file?.url,
    goalImage: data?.[0]?.fields?.items?.[2]?.image?.fields?.file?.url
  }
}

export const setMembershipButtons = (data, currentSaves, onClickButton) => {
  return data?.map((item) => {
    return {
      icon: item?.icon?.fields?.file?.url,
      text: item?.label,
      iconLabel: item?.icon?.fields?.title,
      onClick: () => {
        onClickButton(item?.label?.toLowerCase(), item?.action)
      },
      currentSaves: item?.label?.toLowerCase().includes('ahorrado')
        ? currentSaves || '$ -'
        : null
    }
  })
}

export const setMembershipBlock = (data) => {
  if (!data?.[0]) return null
  return {
    icon: data?.[0]?.icon?.fields?.file?.url,
    textColor: data?.[0]?.labelColorText,
    labelText: data?.[0]?.labelNewText,
    backgroundColor: data?.[0]?.labelColorBackground,
    title: data?.[0]?.subtitulo,
    subtitle: data?.[0]?.subtitulo2,
    buttonLabel: data?.[0]?.buttonText
  }
}

export const setNextMembershipCycle = () => {
  const date = new Date()
  const day = date.getDate()
  const monthNextYear = date.getMonth() + 1 === 12 ? 1 : date.getMonth() + 2
  const monthRaw = day < 5 ? date.getMonth() + 1 : monthNextYear
  const month = monthRaw < 10 ? `0${monthRaw}` : monthRaw
  const year =
    day >= 5 && date.getMonth() + 1 === 12
      ? date.getFullYear() + 1
      : date.getFullYear()

  return `05/${month}/${year}`
}
