import { useEffect } from 'react'
import { useRouter } from 'next/router'
import {
  BigScreen,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  Button,
  Column,
  Container,
  MobileModalWrapper,
  Row,
  Spacer
} from '@smu-chile/pkg-unimarc-components'
import {
  BodyPageProducts,
  BodyPageProductsProps,
  LegalTerms,
  MonetiseBanner
} from 'components'
import { orderByOptions } from 'shared/helpers'
import { useAndesMLClickEvent } from '@smu-chile/pkg-unimarc-hooks'
import { FacetsAndOrderByFiltersContent } from 'components/FacetsAndOrderByFiltersContent'
import { FACETS_AND_ORDERS_BY_FILTERS } from 'shared/utils/constanst'
import { useHandleRouteChanges } from './hooks/useHandleRouteChanges'

export type SearchBodyProductProps = JSX.IntrinsicAttributes &
  BodyPageProductsProps

export const Main = ({ children }: { children: React.ReactNode }) => {
  return (
    <Container
      alignItems='start'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      tagName='main'
    >
      {children}
    </Container>
  )
}

export const SearchBodyProduct = (
  dataOfBodyPageProducts: SearchBodyProductProps
) => {
  const bodyPageProps = dataOfBodyPageProducts
  const {
    bannerImages,
    contentLegalParagraph,
    contentLegalTitle,
    showFiltersModal,
    filterData,
    isMobile,
    selectedRadio,
    disabledButton,
    selectedTab,
    setSelectedTab,
    onClickRadioOrderByOption,
    onApplyFilterModal,
    handleToggleFiltersModal
  } = bodyPageProps
  const router = useRouter()
  const { handleClickOnAndesMLBanner } = useAndesMLClickEvent()
  // Handle route changes and manage the opacity and pointer events of an element
  useHandleRouteChanges()

  useEffect(() => {
    if (!router.query?.orderBy) {
      onClickRadioOrderByOption('Recomendados')
    }
  }, [router.query?.orderBy])

  return (
    <Main>
      <BigScreen>
        <Column
          alignItems='center'
          id='searchBodyProduct_column'
        >
          {!dataOfBodyPageProducts?.isLoadingBannerAndes &&
            (dataOfBodyPageProducts?.bannerImages ||
              dataOfBodyPageProducts?.bannerAndesMl) && (
              <MonetiseBanner
                data={dataOfBodyPageProducts?.searchBannerData}
                handleClickOnAndesMLBanner={handleClickOnAndesMLBanner}
                imageAndes={dataOfBodyPageProducts.bannerAndesMl.imageUrl}
                images={dataOfBodyPageProducts?.bannerImages}
                key={dataOfBodyPageProducts?.query}
                padding='32px 0 0 0'
                urlLink={dataOfBodyPageProducts?.urlLinkOffer}
              />
            )}
          {!bannerImages && <Spacer.Horizontal size={24} />}
          <Container
            backgroundColor={getGlobalStyle('--color-background-white')}
            isWrap
            maxWidth={getGlobalStyle('--width-max-desktop')}
            minHeight='90vh'
            padding='10px 0px'
            width='1120px'
          >
            <Column alignItems='center'>
              <BodyPageProducts {...dataOfBodyPageProducts} />
            </Column>
            <LegalTerms
              paragraph={contentLegalParagraph}
              title={contentLegalTitle}
            />
          </Container>
        </Column>
      </BigScreen>
      <SmallScreen>
        <Column id='searchBodyProduct_column'>
          {showFiltersModal && (
            <MobileModalWrapper
              blockId='facetsAndOrderByFilters'
              body={
                <FacetsAndOrderByFiltersContent
                  amountOfProducts={dataOfBodyPageProducts.products?.length}
                  facetFilterProps={filterData}
                  isMobile={isMobile}
                  onClickDetectTab={setSelectedTab}
                  orderByProps={{
                    onClick: onClickRadioOrderByOption,
                    options: orderByOptions,
                    selected: selectedRadio
                  }}
                />
              }
              headerLeftElement={
                selectedTab === 0 &&
                filterData.hasFilter && (
                  <Column
                    flexGrow={0}
                    width='auto'
                  >
                    <Button
                      color={getGlobalStyle('--color-primary-light-red')}
                      fontSize='12px'
                      fontWeight='600'
                      height='initial'
                      label='Limpiar'
                      minWidth='auto'
                      onClick={filterData.handleClearOnClick}
                      type='plain'
                    />
                  </Column>
                )
              }
              headerTitle={FACETS_AND_ORDERS_BY_FILTERS.TITLE}
              iconSize={20}
              isEnableButton={!disabledButton || filterData?.enableButton}
              modalConfigsProps={{
                toggle: handleToggleFiltersModal,
                isOpen: showFiltersModal,
                toggleOutside: handleToggleFiltersModal
              }}
              onApply={() => {
                if (selectedTab === 1) {
                  return onApplyFilterModal()
                }
                if (selectedTab === 0) {
                  return filterData.handleApplyOnClick(handleToggleFiltersModal)
                }
                return null
              }}
              onClose={handleToggleFiltersModal}
            />
          )}
          <Row>
            <Column
              backgroundColor={getGlobalStyle('--color-base-white')}
              id='searchBodyProduct_column'
            >
              <BodyPageProducts {...dataOfBodyPageProducts} />
              <LegalTerms
                paragraph={contentLegalParagraph}
                title={contentLegalTitle}
              />
            </Column>
          </Row>
        </Column>
      </SmallScreen>
    </Main>
  )
}
