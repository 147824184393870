import {
  dataLayerUniqueTypeEvent,
  deleteLastSeenProducts,
  deleteSearchHistory,
  isValidArrayWithData,
  productClickTag,
  setCookie,
  shelvesImpression,
  sleep
} from '@smu-chile/pkg-unimarc-hooks'
import { formatProductUrl } from '@smu-chile/pkg-unimarc-hooks/shared/helpers/formatProductUrl'
import { debounce } from 'lodash'
import { normalizeQuery } from 'shared/helpers'
import { dropDownSearchOpacityOn } from 'shared/helpers/dropDownSearchOpacityOn'

const isVisibleFunction = (isVisible: boolean) => {
  const backgroundSearch = isVisible ? 'white' : 'transparent'
  const borderSearch = isVisible ? '1px solid #C4C4C4' : '1px solid white'
  return { backgroundSearch, borderSearch }
}

type fontSizeType = 'sm' | 'md' | 'lg'
const debouncedOnChange = debounce(
  (value: string, onChangeCallback: (value: string) => void) => {
    onChangeCallback(value)
  },
  1000
)

let lastValue = ''

export const searchProps = ({
  handleAdd,
  handleChange,
  handleChangeSearch,
  handleOnClick,
  handleRemove,
  inputAdornmentRef,
  isLoadingNextSearch,
  isMobile,
  isVisible,
  isVisibleClearIcon,
  queryClient = null,
  result,
  value,
  router = null,
  setActiveMenu,
  setIsVisible,
  setIsFocused,
  setIsVisibleClearIcon,
  setValue,
  shelvesData,
  setIsLoadingNextSearch
}) => {
  const { backgroundSearch, borderSearch } = isVisibleFunction(isVisible) || {}
  const site = 'Unimarc'
  const checkIsMobile = isMobile
    ? isMobile
    : typeof window !== 'undefined'
    ? window.innerWidth < 1280
    : false
  // Get an array of items that return the search
  const suggestionsDataTopSearch =
    result?.mostWanted?.data?.suggestions?.map((item) => {
      return {
        text: `${item?.term}`,
        onClick: () => {
          setCookie({
            cookieName: 'searchDatalayer',
            cookieValue: 'clickMostBuyed'
          })
          return handleOnclickHeadSearch(item?.term)
        }
      }
    }) || []

  // Get an array of suggestions that return the search
  const suggestionsDataSearch = Array.isArray(
    result?.searchSuggestions?.data?.suggestions
  )
    ? result?.searchSuggestions?.data?.suggestions?.map((item: string) => {
        return {
          text: `${item}`,
          onClick: () => {
            return handleOnclickHeadSearch(item)
          }
        }
      })
    : []

  const searchHistoryData =
    (isValidArrayWithData(result?.searchHistory?.data) &&
      result.searchHistory.data.map((item: string) => {
        return {
          text: `${item}`,
          onClick: () => {
            setCookie({
              cookieName: 'searchDatalayer',
              cookieValue: 'clickYourSearchs'
            })
            return handleOnclickHeadSearch(item)
          }
        }
      })) ||
    []

  // Get an array of items with matches that return the search and return the structure required
  const intelligentData =
    shelvesData?.map((item) => {
      const maxQuantity =
        item?.cartLimit > 0
          ? item?.cartLimit
          : item?.sellers?.[0]?.availableQuantity
      return {
        ...item,
        img: item?.images[0],
        inOffer: item?.sellers[0]?.inOffer,
        itemId: item?.itemId,
        listPrice: item?.sellers[0]?.listPrice,
        maxQuantity,
        measurementUnit: item?.measurementUnit,
        price: item?.sellers[0]?.price,
        product: item,
        productUrl: formatProductUrl(item?.detailUrl),
        title: item?.['descriptionShort'] || item?.name,
        onClickProduct: () => {
          productClickTag({ product: item, isSearch: true, site })
        }
      }
    }) || []

  const headSearchData = () => {
    const isValid = intelligentData?.length > 0
    const suggestionsTitle = isValid ? 'Sugerencias' : 'Más buscados'
    const suggestionsData = isValid ? suggestionsDataSearch : {}

    return {
      cleanLastSeenProducts: () => {
        deleteLastSeenProducts()
        queryClient.refetchQueries('lastSeenProducts')
      },
      cleanSearchHistory: () => {
        deleteSearchHistory()
        queryClient.refetchQueries('searchHistory')
      },
      suggestionsTitle,
      suggestionsData: { suggestionsData, suggestionsDataTopSearch }
    }
  }

  const handleCloseSearch = () => {
    if (router?.query?.suggestions) {
      if (checkIsMobile) {
        setIsVisibleClearIcon(false)
        setValue('')
        setIsVisible((prev: boolean) => {
          return !prev
        })
      } else {
        setIsVisible(false)
      }
      setIsFocused(true)
      const searchInputHeader = document.querySelectorAll(
        '#search-header__input'
      )
      if (searchInputHeader.length > 1) {
        const inputElement = searchInputHeader[1] as HTMLInputElement
        inputElement.focus()
        inputElement.setAttribute('focus', 'true')
      }
    } else {
      setIsVisibleClearIcon(false)
      setIsVisible(false)
    }
  }

  const handleClearInputSearch = () => {
    if (inputAdornmentRef.current && inputAdornmentRef.current.children[0])
      inputAdornmentRef.current.children[0].children[0].value = ''

    setValue('')
    setIsVisibleClearIcon(false)
  }

  const getUrlSearch = (term) => {
    // we use replace to prevent problems with compose searchs
    const termUrl = term.replace(/\s/g, '-')
    return `/search?q=${termUrl}`
  }

  const handleOnclickHeadSearch = (term: string) => {
    if (router) {
      router.push(getUrlSearch(term))
    } else {
      window.location.href = getUrlSearch(term)
    }

    dataLayerUniqueTypeEvent({
      event: 'search',
      search_term: term?.['term'] || term,
      search_type: intelligentData?.length > 0 ? 'Sugeridos' : 'Top Search'
    })
  }

  const handleKeywordKeyPressSearch = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key == 'Enter') {
      // add opacity to dropdown during the ssr loading
      dropDownSearchOpacityOn('dropDownSearchV2__column')
      // handle the tree dots of loading suggestions
      setIsLoadingNextSearch(false)
      window.requestAnimationFrame(() => {
        debouncedOnChange.cancel()
      })
      setCookie({ cookieName: 'searchDatalayer', cookieValue: 'enter' })
      handleOnclickSearch()
    }
  }

  const handleOnBlur = () => {
    setIsFocused(false)
  }

  const onChangeCallback = async (targetValue: string) => {
    if (!router) return null

    try {
      if (targetValue.trim() === '' || targetValue.length < 3) return null
      // start showing the tree dots of loading suggestions
      const cleanParams = ['categories', 'offers', 'login']
      const query = {
        ...router.query,
        q: targetValue.replace(/\s+/g, '-'),
        suggestions: true
      }

      cleanParams.forEach((key) => {
        return delete query[key]
      })

      const currentQuery = router.query
      const hasQueryChanged =
        query.q !== currentQuery.q ||
        query.suggestions !== currentQuery.suggestions

      if (hasQueryChanged) {
        setIsLoadingNextSearch(true)
        const normalizedQuery = normalizeQuery(query)
        await router.push({
          pathname: '/search',
          query: { q: normalizedQuery?.q, suggestions: true }
        })
        setIsLoadingNextSearch(false)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error en onChangeCallback:', error)
    }
    return null
  }

  const handleOnChangeSearch = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsFocused(true)
    const targetValue = e.target.value.toLocaleLowerCase()
    setIsVisibleClearIcon(targetValue.length > 0)

    if (!router?.query?.suggestions) {
      setIsVisible(true)
      setActiveMenu('')
    }

    // search
    handleChangeSearch(targetValue.trimStart())
    setValue(targetValue)

    // execute debounce only if the value has changed
    if (e.type !== 'focus' && lastValue !== targetValue) {
      lastValue = targetValue
      debouncedOnChange(targetValue, onChangeCallback)
    }
    setIsLoadingNextSearch(false)
  }

  const handleOnclickSearch = () => {
    if (value?.length === 0 || value?.match(/^\s*$/)?.index === 0) return
    if (router) {
      router.push(getUrlSearch(value))
    } else {
      window.location.href = getUrlSearch(value)
    }

    if (inputAdornmentRef.current && inputAdornmentRef.current.children[0])
      inputAdornmentRef.current.children[0].children[0].blur()

    setIsVisibleClearIcon(false)
  }

  const shelvesSearchImpressions = async (shelves) => {
    if (!shelves[0]?.ref) return
    await sleep(300)
    shelvesImpression({
      isSearch: true,
      parent: shelves[0].ref.parentNode.parentNode,
      shelves,
      site
    })
  }

  return {
    backgroundSearch,
    borderSearch,
    clearButton: isVisibleClearIcon,
    dropDownSearch: {
      bodySearch: {
        handleSearchImpressions: shelvesSearchImpressions,
        data: intelligentData,
        quantityButtonProps: {
          quantityButtonStyles: {
            fontSize: 'sm' as fontSizeType,
            maxHeight: '34px',
            maxWidth: '130px',
            minWidth: '130px',
            maxHeightIcon: '30px',
            sizeCircle: 18,
            padding: '0px 2px'
          },
          handleAdd,
          handleRemove,
          handleChange
        },
        handleOnClickShelfSearch: handleOnClick
      },
      headSearch: headSearchData(),
      isLoadingSearch: isLoadingNextSearch,
      valueInputSearch: value,
      lastSeenProducts: result?.lastSeenProducts?.data || [],
      searchHistory: searchHistoryData || []
    },
    isVisibleDropDownSearch: isVisible,
    setIsFocusedInput: setIsFocused,
    inputAdornmentRef,
    searchPlaceholder: '¿Qué quieres comprar?',
    valueInputSearch: value,
    onBlurSearch: handleOnBlur,
    onChangeSearch: handleOnChangeSearch,
    onClearInput: handleClearInputSearch,
    onClickSearch: handleOnclickSearch,
    onCloseSearch: handleCloseSearch,
    onFocusSearch: handleOnChangeSearch,
    onKeyPressSearch: handleKeywordKeyPressSearch
  }
}
