interface ImageFile {
  url: string
}

interface ImageFields {
  description: string
  file: ImageFile
  title: string
  fields: ImageFields
  sys?: {
    id: string
  }
}

interface Image {
  fields: ImageFields
}

interface Fields {
  image: ImageFields
  label: string
  reference?: string
  referenceWeb?: string
  referenceType: string
}

export interface ImageBanner {
  fields: Fields
  image: Image
  label: string
  reference?: string
  referenceWeb?: string
  referenceType: string
  unimarcEnabledLevels?: string[]
}

interface Asset {
  fields: ImageFields
  sys: {
    id: string
  }
}

export interface ClubUnimarcStaticBanner {
  includes: {
    Entry: ImageBanner[]
    Asset: Asset[]
  }
}

export interface BannersClubAhorro {
  height: number
  idFormato: number
  imageBanner: ImageBanner[]
  label: string
  layout: string
  subtitle: string
  title: string
  sectionIcon?: Asset
}

export interface BannersCarrousel {
  image: string
  img: string
  onRedirect: () => void
}

export enum ClubUnimarcBannerLayout {
  Banner = 'banner',
  Carousel = 'carousel'
}

export enum ClubUnimarcBannerLabels {
  CouponsBlock = 'componente-vacio-cupones'
}
