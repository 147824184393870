import HttpClient from '@smu-chile/pkg-unimarc-hooks/shared/HttpClient'
import { queryClient } from 'shared/utils'

export const updateSalesChannel = async (salesChannel: number) => {
  await HttpClient.getInstance()
    .invoke(
      `${window.location.origin}/api/setCookie?co_sc=${salesChannel}`,
      'POST',
      'setCookie()'
    )
    .then((response) => {
      return response.data
    })
  queryClient.invalidateQueries()
}
